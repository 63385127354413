<template>
  <div class="visit-owner">
    <div class="fontWeight500 textStyle fontSize16 colorBlack">Who needs help?</div>
    <v-skeleton-loader
      v-if="usersLoading || isSessionLoading"
      :boilerplatstartTimee="true"
      class="mx-0 full-width"
      elevation="2"
      type="list-item-three-line, list-item-three-line"
    ></v-skeleton-loader>
    <template v-else>
      <div class="wrap-list">
        <v-radio-group :hide-details="true" :value="patientId" class="pt-0" @change="onSelectPatient">
          <v-radio v-for="item in patientOptions" :key="item.id" :value="item.id">
            <template v-slot:label>
              <v-list-item-avatar>
                <Avatar :src="item.photoURL" :userId="item.value" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <a v-if="acceptPediatricVisits" class="font-weight-bold pt-3 d-block" @click="addDependent()"> Add dependent</a>
    </template>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { DependentsApi } from "@/api/dependents";
import Avatar from "@/components/Avatar/Index.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { usePatientVisitScheduleStore } from "@/pinia-store/patientVisitSchedule";
import { useVisitSettingsStore } from "@/pinia-store/visitSettings";
import { routesEnum } from "@/types/Routes.enum";

export default {
  name: "VisitOwner",
  components: { Avatar },
  data: () => ({
    usersLoading: false,
  }),
  computed: {
    ...mapState(useVisitSettingsStore, ["acceptPediatricVisits"]),
    ...mapState(useAuthStore, ["uid", "photoURL", "displayName"]),
    ...mapState(usePatientVisitScheduleStore, ["patientId", "patients", "isSessionLoading"]),
    patientOptions() {
      return this.patients
        .filter((p) => {
          if (!this.acceptPediatricVisits && p?.isDependent) return false;
          return true;
        })
        .map((patient) => ({
          ...patient,
          value: patient.id,
          name: patient.name ? patient.name : `${patient.firstName || ""} ${patient.lastName || ""}`,
        }));
    },
  },
  methods: {
    ...mapActions(usePatientVisitScheduleStore, ["setPatientId", "setPatients"]),
    onSelectPatient(value) {
      this.setPatientId(value);
    },
    addDependent() {
      this.$router.push({ name: routesEnum.patientDependentsRegister, query: { kind: "child" } });
    },
    customFilter(item, queryText) {
      const firstName = item?.firstName?.toLowerCase();
      const lastName = item?.lastName?.toLowerCase();
      const phoneNumber = item?.phoneNumber?.toLowerCase() || "";
      const email = item?.email?.toLowerCase() || "";
      const searchText = queryText.toLowerCase();
      return (
        firstName.indexOf(searchText) > -1 ||
        lastName.indexOf(searchText) > -1 ||
        email.indexOf(searchText) > -1 ||
        phoneNumber.indexOf(searchText) > -1
      );
    },
  },
  async mounted() {
    try {
      this.usersLoading = true;
      let dependents = [];
      if (this.acceptPediatricVisits) dependents = await DependentsApi.getByInviterIdV1Minor(this.uid);
      if (!this.patientId) this.setPatientId(this.uid);
      this.setPatients([
        {
          uid: this.uid,
          id: this.uid,
          name: this.displayName,
        },
        ...(dependents || []).map((dependent) => ({ ...dependent, id: dependent.id, isDependent: true })),
      ]);
    } catch (e) {
      console.error(e);
    }
    this.usersLoading = false;
  },
};
</script>
<style lang="scss" scoped>
.visit-owner {
  padding: 30px 32px 32px;

  .wrap-list {
    max-height: 260px;
    overflow-x: auto;

    :deep .v-radio {
      flex-direction: row-reverse;
      padding: 0 5px;
      border: 1px solid transparent;
      border-radius: 4px;

      &.v-item--active {
        border-color: $primary;
      }
    }
  }
}
</style>
